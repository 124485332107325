<template>
  <Modal :id="id" :modalTitle="modalTitle" :class="classes">
    <div v-if="loadingModal" class="modal-loader d-flex align-items-center justify-content-center">
      <img src="@/assets/loader.svg" height="48" alt="">
    </div>
    <div v-else>
      <template v-if="view=='SyncWallet'">
        <form @submit.prevent="submit()" autocomplete="off">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="wallet">Wallet address</label>
                <input id="wallet" class="form-control" type="text" v-model="wallet" placeholder="Connect your wallet or type your address"/>
                <a href="javascript:void(0)" v-if="!$checkMetamask() || accounts.length == 0 " class="btn btn-outline-primary" @click="$connectMetamask()"><font-awesome-icon :icon="['fas', 'wallet']" /> Connect Wallet</a>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="chain">Chain</label>
                <select id="chain" class="form-control" v-model="chain">
                  <option v-for="(chain,index) in chainsList" v-bind:key="index" :value="chain.chain">{{ chain.name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-end">
              <button class="btn btn-primary" v-bind:class="{ disabled: v$.$invalid }" :disabled="v$.$invalid">
                <span v-if="!loading">Sync wallet</span>
                <img v-if="loading" src="@/assets/loader.svg" height="20" alt="">
              </button>
            </div>
          </div>
        </form>
      </template>
      <template v-if="view=='ImportCrypto'">
        <p>Choose the tokens you want to add</p>
        <div class="row" v-if="validTokens.length > 0">
          <div class="col-12" v-for="(crypto, index) in validTokens" v-bind:key="index">
            <div class="card mb-3">
              <div class="card-body py-2">
                <div class="row">
                  <div class="col-9">
                    <div class="row align-items-center">
                      <div class="col-7" style="line-height:1.2">
                        <span class="float-left w-100 white-space-nowrap text-overflow-ellipsis overflow-hidden">{{ crypto.name }}</span>
                        <small class="float-left mr-2 text-muted font-size-075">{{ crypto.symbol }}</small>
                      </div>
                      <div class="col-5 white-space-nowrap text-overflow-ellipsis overflow-hidden">
                        {{ (crypto.balance / parseFloat('1e' + crypto.decimals)).toFixed(3)}}
                      </div>
                    </div>
                  </div>
                  <div class="col-3 text-center pr-0 ailign-items-center d-flex justify-content-center">
                    <div class="border-left w-100 d-flex justify-content-center align-items-center">
                      <template v-if="crypto.notFound == 'false'">
                        <a v-if="crypto.loading == 'false'" href="javascript:void(0)" class="btn btn-link px-2" @click="addCrypto(crypto,index)">Add</a>
                        <span v-if="crypto.loading == 'true'" class="px-2 align-items-center pb-2" style="line-height: 1.85;"><img class="" src="@/assets/loader.svg" height="20" alt=""></span>
                        <span v-if="crypto.loading == 'done'" class="text-success px-2 py-1" style="line-height: 1.85;"><font-awesome-icon :icon="['far', 'check-circle']"/></span>
                      </template>
                      <template v-else>
                        <span class="text-danger px-2 py-1" style="line-height: 1.85;">Error</span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <button class="btn btn-primary" @click="closeModal()">
              <span>Done</span>
            </button>
          </div>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>

import Modal from '../../../../Shared/Modal.vue';
import axios from 'axios';
import useVuelidate from '@vuelidate/core';
import { required, minLength, numeric, minValue, requiredIf } from '@vuelidate/validators';
import chains from '../../../../../../data/chains.json'
import Web3 from 'web3';
import Moralis from 'moralis';

export default {
  name: 'ImportCryptos',
  props: [
    'id',
    'modalTitle',
    'class',
    'index',
    'MyCryptosList',
  ],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      view: 'SyncWallet',
      wallet: '',
      chain: '',
      chainsList: chains,
      accounts: '',
      loading: false,
      validTokens: '',
      cryptosAvailablesList: [],
    };
  },
  created() {

    if(this.$checkMetamask()){
      this.$getAccountsMetamask().then((result) => {
        this.accounts = result;
        this.wallet = result[0]
      });
    }
  },
  updated(){

    this.cryptos = [];
    //this.view = 'SyncWallet';
    this.loading = false;
    this.wallet = '';
    this.chain = '';

    if(this.$checkMetamask()){
      this.$getAccountsMetamask().then((result) => {
        this.accounts = result;
        this.wallet = result[0]
      });
    }
    
    this.myCryptosListAux = [];
    this.myCryptosListAux = this.myCryptosListAux.concat(this.MyCryptosList);

    axios.get("/cryptos/user/my-cryptos")
      .then((response)=> {
        this.cryptosAvailablesList = response.data.body.user_cryptos;
      });
      
  },
  validations() {
    return {
      wallet : {
        required
      },
      chain : {
        required
      }
    }
  },
  components: {
    Modal
  },
  methods: {
    closeModal(){
      document.querySelector('#SyncFromWallet .close').click();
      this.cryptos = [];
      this.view = 'SyncWallet';
      this.loading = false;
      this.wallet = '';
      this.chain = '';
    },
    async submit(){
      this.loading = true;
      await this.getTokensFromWallet();
      this.loading = false;
      this.view = 'ImportCrypto';
    },
    async getTokensFromWallet(){
      const response = await Moralis.EvmApi.token.getWalletTokenBalances({
        "chain": this.chain,
        "address": this.wallet
      });

      this.validTokens = response.raw.filter((e)=> e.possible_spam === false )

      this.validTokens.forEach((elem) => {elem['loading'] = 'false';elem['notFound'] = 'false'})
    },
    addCrypto(crypto,index){
      this.validTokens[index].loading = 'true';
      axios.get(`/cryptos/symbol-info?contract=${crypto.token_address}`)
        .then((response)=> {
          console.log(response);
          if(response.data.body.status.error_code == 0){
            let crypto_detail = response.data.body.data[Object.keys(response.data.body.data)[0]]

            const bodyToSend = {
              id_coinmarketcap: crypto_detail.id
            }
            axios.post(`/cryptos/symbol-import`, bodyToSend)
              .then((response) => {

                this.myCryptosListAux.push({
                  id_crypto: parseInt(response.data.body.ID),
                  symbol: crypto.symbol,
                  owned: (crypto.balance / parseFloat('1e' + crypto.decimals)).toFixed(3),
                  img: crypto_detail.logo
                })  
                const bodyToSend = this.myCryptosListAux;
                axios.put(`/cryptos/user/my-cryptos`,bodyToSend)
                  .then(()=> {
                    this.loading = false;
                    this.$emit('userCryptosEdited');
                    this.validTokens[index].loading = 'done';
                  });
              });
          }
          else{
            this.validTokens[index].notFound = 'true';
            this.validTokens[index].loading = 'false';
          }
        });

    },
  }
};
</script>

<style>

</style>