<template>
  <Modal :id="id" :modalTitle="modalTitle" :class="classes">
    <div v-if="loadingModal" class="modal-loader d-flex align-items-center justify-content-center">
      <img src="@/assets/loader.svg" height="48" alt="">
    </div>
    <div v-else>
      <template v-if="view=='import'">
        <form id="importFormCrypto" @submit.prevent="submit()" autocomplete="off">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <label for="importSymbol">Type a Symbol or Address Contract you want to import</label>
                <div class="row">
                  <div class="col-12 col-sm-9 pr-sm-0">
                    <input tabindex="0" placeholder="Symbol or Address" type="text" class="form-control text-uppercase" id="importSymbol" v-model.trim="v$.symbol.$model">
                  </div>
                  <div class="col-sm-3 col-12 mt-3 mt-sm-0">  
                    <button class="btn btn-primary w-100" v-bind:class="{ disabled: v$.$invalid }" :disabled="v$.$invalid">
                      <span v-if="!loading">Search</span>
                      <img v-if="loading" src="@/assets/loader.svg" height="20" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="cryptos.length > 0">
            <div class="col-12" v-for="(crypto, index) in cryptos" v-bind:key="index">
              <div class="card mt-3" v-if="crypto.contract_address.length > 0">
                <div class="card-body py-2">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="mr-2 d-flex align-items-center">
                      <span class="mr-2">
                        <img class="float-left" width="24" :src="crypto.logo"/>
                      </span>
                      <div style="line-height:1.2">
                        <span class="float-left w-100">{{ crypto.name }}</span>
                        <small class="float-left mr-2 text-muted font-size-075">{{ crypto.symbol }}</small>
                      </div>
                    </div>
                    <div>
                      <a v-if="!checkIfExistsInAvailableCryptos(crypto)" href="javascript:void(0)" @click="importCrypto(crypto)">
                        <font-awesome-icon class="mr-2" :icon="['fas', 'plus']" /> Add
                      </a>
                      <span v-else class="text-success">Already imported</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="crypto.contract_address.length <= 0 && cryptos.length == 1">
                <div class="alert alert-danger mt-3 mb-0">Cryptocurrency does not have a valid contract. Try again with other crypto.</div>    
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="cryptoNotFound" class="alert alert-danger mt-3 mb-0">Cryptocurrency not found.</div>
          </div>
        </form>
      </template>
      <template v-if="view == 'quantity'">
        <div class="row">
          <div class="col-12">
            <form @submit.prevent="addQuantity()" autocomplete="off">
              <div class="row">
                <div class="col-12">
                  <div class="alert alert-success"><strong>{{ symbol.toUpperCase() }}</strong> has been imported successfully</div>
                  <p>If you wish, you can add quantity of {{ symbol.toUpperCase() }} to your wallet</p>
                  <input min="0" placeholder="0.00" step="any" type="number" class="form-control" id="quantity" v-model.trim.lazy="v$.quantity.$model"/>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 d-flex justify-content-end">
                  <button class="btn btn-outline-link mr-3 border-0" @click="closeModal()">Omit</button>

                  <button class="btn btn-primary" v-bind:class="{ disabled: v$.$invalid }" :disabled="v$.$invalid">
                    <span v-if="!loading">Save</span>
                    <img v-if="loading" src="@/assets/loader.svg" height="20" alt="">
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>

import Modal from '../../../../Shared/Modal.vue';
import axios from 'axios';
import useVuelidate from '@vuelidate/core';
import { required, minLength, numeric, minValue, requiredIf } from '@vuelidate/validators';

export default {
  name: 'ImportCryptos',
  props: [
    'id',
    'modalTitle',
    'class',
    'index',
    'MyCryptosList',
  ],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      symbol: '',
      quantity: '',
      cryptos: [],
      view: 'import',
      loading: false,
      loadingModal: false,
      cryptoNotFound: false,
      selectedCrypto: '',
      cryptosAvailablesList: [],
    };
  },
  created() {
  },
  updated(){
    this.cryptos = [];
    this.view = 'import';
    this.symbol = '';
    this.quantity = '';
    this.myCryptosListAux = [];
    this.myCryptosListAux = this.myCryptosListAux.concat(this.MyCryptosList);
    axios.get("/cryptos/availables-cryptos")
      .then((response)=> {
        this.cryptosAvailablesList = response.data.body;
      });
  },
  validations() {
    return {
      symbol: {
        required: requiredIf(function(){ return this.view === 'import' }),
        minLength: minLength(2)
      },
      quantity: {
        required: requiredIf(function(){ return this.view === 'quantity' }),
        numeric,
        minValue: minValue(-0.999999999)
      }
    } 
  },
  components: {
    Modal
  },
  methods: {
    submit() {
      this.loading = true;
      this.cryptos = [];
      this.cryptoNotFound = false;

      let filter = '';

      if(this.symbol.startsWith('0x')){
        filter = `contract=${this.symbol}`
      }
      else{
        filter = `symbol=${this.symbol}`
      }

      axios.get(`/cryptos/symbol-info?${filter}`)
        .then((response)=> {
          if(response.data.body.status.error_code == 0){
            if(!this.symbol.startsWith('0x')){
              this.cryptos = response.data.body.data[this.symbol.toUpperCase()];
            }
            else{
              this.cryptos[0] = response.data.body.data[Object.keys(response.data.body.data)[0]]
            }
          }
          else{
            this.cryptoNotFound = true;
          }
          this.loading = false;
        });

    },
    importCrypto(crypto) {
      this.selectedCrypto = crypto;
      this.loadingModal = true;
      const bodyToSend = {
        id_coinmarketcap: crypto.id
      }
      axios.post(`/cryptos/symbol-import`, bodyToSend)
        .then((response) => {
          this.view = 'quantity';
          this.loadingModal = false;
          this.selectedCrypto['ID'] = response.data.body.ID;
        });
    },
    addQuantity(){
      this.myCryptosListAux.push({
        id_crypto: parseInt(this.selectedCrypto.ID),
        symbol: this.selectedCrypto.symbol,
        owned: parseFloat(this.quantity),
        img: this.selectedCrypto.logo
      })  
      const bodyToSend = this.myCryptosListAux;
      axios.put(`/cryptos/user/my-cryptos`,bodyToSend)
        .then(()=> {
          this.loading = false;
          this.closeModal();
          this.$emit('userCryptosEdited');
        });
    },
    closeModal(){
      document.querySelector('#ImportCrypto .close').click();
      this.cryptos = [];
      this.view = 'import';
      this.loadingModal = false;
      this.loading = false;
    },
    checkIfExistsInAvailableCryptos(item){
      let exists = this.cryptosAvailablesList.filter((e)=> e.id_coinmarketcap == item.id)
      return exists.length > 0 ? true : false;
    }
  }
};
</script>

<style>

</style>